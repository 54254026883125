import React from "react"
import { Helmet } from "react-helmet"
import { Flex, Box, Text, Heading } from "rebass"
import { Header } from "../components/header"
import { Footer } from "../components/footer"
import { Container } from "../components/container"
import { ContactCard } from "../components/contact-card"
export default ({ pageContext: { title, blurb, contactDetails } }) => (
  <>
    <Helmet>
      <title>Melanie Couper {title}</title>
    </Helmet>
    <Header />
    <Container>
      <Flex flexDirection={["column", "column", "row"]}>
        <Box flexBasis="50%" py={3} mr={5} mb={[4, 4, 0]}>
          <Heading
            color="darkGrey"
            fontFamily="body"
            mb={4}
            fontSize={5}
            fontWeight={300}
          >
            {title}
          </Heading>
          <Text
            fontFamily="body"
            color="darkGrey"
            fontWeight="bold"
            lineHeight="1.3"
          >
            {blurb}
          </Text>
        </Box>
        <ContactCard {...contactDetails} width={["100%", "430px"]} />
      </Flex>
    </Container>
    <Footer />
  </>
)
